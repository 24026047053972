import { Loader, Progress } from '@mantine/core';
import { CSSProperties } from 'react';

export const LoadingView = ({
  includeTitle = true,
  includeAnimation = false,
  progress = undefined,
  style,
  title,
  description,
}: {
  title?: string;
  description?: string;
  includeTitle?: boolean;
  includeAnimation?: boolean;
  progress?: number;
  style?: CSSProperties;
}) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...style,
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        {includeTitle === true && (
          <h3 style={{ color: '#999', fontWeight: '400' }}>
            {title || 'Loading...'}
          </h3>
        )}
        {description && <p>{description}</p>}
        {includeAnimation === true && <Loader type='dots' color='pink.4' />}
        {!!progress && <Progress value={progress} style={{ width: '100%' }} />}
      </div>
    </div>
  );
};
