import { isValid } from 'date-fns';

/**
 * Represents a "date" without a time.
 *
 * This type exists to make it clear that the time portion of the date is not
 * relevant. Examples include the date an expense was incurred, or the date
 * a contract was signed.
 *
 * Instances of this type should appear as the same date to all users,
 * regardless of timezone.
 */
/**
 * Represents a date with year, month, and day.
 */
export class YearMonthDay {
  static #ymdRegex = /^ymd-(\d{4})-(\d{2})-(\d{2})$/;

  readonly year: number;
  readonly month: number;
  readonly day: number;

  constructor(props: { year: number; month: number; day: number }) {
    this.year = props.year;
    this.month = props.month;
    this.day = props.day;
  }

  /**
   * Parses a string representation of a date into a YearMonthDay object.
   *
   * @param str - The string representation of the date.
   * @param format - The format of the string representation.
   * @returns The parsed YearMonthDay object if the string is valid, otherwise undefined.
   */
  static fromString = (
    str: string,
    format: YmdStringFormat
  ): YearMonthDay | undefined => {
    // Sanity check before parsing
    if (!isValid(new Date(str))) {
      return undefined;
    }

    let [year, month, day] = [-1, -1, -1];
    switch (format) {
      case 'yyyy-MM-dd': {
        [year, month, day] = str.split('-').map(Number);
        break;
      }
      case 'MM/dd/yyyy': {
        [month, day, year] = str.split('/').map(Number);
        break;
      }
      case 'M/d/yyyy': {
        [month, day, year] = str.split('/').map(Number);
        break;
      }
    }

    // Sanity check after parsing
    const partsValid =
      year >= 0 && month >= 1 && month <= 12 && day >= 1 && day <= 31;
    if (!partsValid) {
      return undefined;
    }
    return new YearMonthDay({ year, month, day });
  };

  static fromFirestoreString = (str: string): YearMonthDay | undefined => {
    const match = str.match(this.#ymdRegex);
    if (!match) {
      return undefined;
    }
    const [, year, month, day] = match;
    return new YearMonthDay({
      year: Number(year),
      month: Number(month),
      day: Number(day),
    });
  };

  /**
   * Returns a string in the specified format.
   */
  toString = (format: YmdStringFormat) => {
    // Note - Padding the year to 4 digits because we've seen dates with years
    // in the hundreds (e.g. 0202-01-01).
    switch (format) {
      case 'yyyy-MM-dd':
        return `${padNumber(this.year, 4)}-${padNumber(
          this.month,
          2
        )}-${padNumber(this.day, 2)}`;
      case 'MM/dd/yyyy':
        return `${padNumber(this.month, 2)}/${padNumber(
          this.day,
          2
        )}/${padNumber(this.year, 4)}`;
      case 'M/d/yyyy':
        return `${this.month}/${this.day}/${padNumber(this.year, 4)}`;
    }
  };

  toDate = () => {
    return new Date(this.year, this.month - 1, this.day);
  };

  /**
   * Returns `ymd`'s milliseconds since midnight, January 1, 1970 UTC.
   */
  toTime = () => {
    return this.toDate().getTime();
  };

  toFirestoreString = () => {
    return `ymd-${this.toString('yyyy-MM-dd')}`;
  };

  /**
   * Checks if the current YearMonthDay is on or after the specified YearMonthDay.
   * @param other - The YearMonthDay to compare against.
   * @returns `true` if `this` YearMonthDay is on or after the `other` YearMonthDay, `false` otherwise.
   */
  isOnOrAfter = (other: YearMonthDay) => {
    return this.toTime() >= other.toTime();
  };

  /**
   * Checks if the current YearMonthDay is before the specified YearMonthDay.
   * @param other - The YearMonthDay to compare against.
   * @returns `true` if `this` YearMonthDay is before the `other` YearMonthDay, `false` otherwise.
   */
  isBefore = (other: YearMonthDay) => {
    return this.toTime() < other.toTime();
  };

  isOnOrBefore = (other: YearMonthDay) => {
    return this.toTime() <= other.toTime();
  };

  isAfter = (other: YearMonthDay) => {
    return this.toTime() > other.toTime();
  };
}

/**
 * Examples:
 *
 * `yyyy-MM-dd`:
 *   - `2022-01-01`
 *   - `2022-12-31`
 *
 * `MM/dd/yyyy`:
 *   - `01/01/2022`
 *   - `12/31/2022`
 *
 * `M/d/yyyy`:
 *   - `1/1/2022`
 *   - `12/31/2022`
 *
 * Format details: http://unicode.org/reports/tr35/tr35-6.html#Date_Format_Patterns
 */
export type YmdStringFormat = 'yyyy-MM-dd' | 'MM/dd/yyyy' | 'M/d/yyyy';

/**
 * Sorts two YearMonthDay objects based on their values.
 *
 * @param ymd1 - The first YearMonthDay object to compare.
 * @param ymd2 - The second YearMonthDay object to compare.
 * @param descending - Optional. Specifies whether the sorting order should be descending. Default is false.
 * @returns A number indicating the sorting order:
 *          - 1 if ymd1 is greater than ymd2
 *          - -1 if ymd1 is less than ymd2
 *          - 0 if ymd1 is equal to ymd2
 *
 * @note Undefined values are always sorted to the end.
 */
export const ymdSortOrder = (
  ymd1: YearMonthDay | undefined,
  ymd2: YearMonthDay | undefined,
  descending: boolean = false
) => {
  if (!ymd1 && !ymd2) {
    return 0;
  }
  if (!ymd1) {
    // Always sort undefined values to the end
    return 1;
  }
  if (!ymd2) {
    // Always sort undefined values to the end
    return -1;
  }

  const order = (ymd1?.toTime() ?? 0) - (ymd2?.toTime() ?? 0);
  return (order > 0 ? 1 : order < 0 ? -1 : 0) * (descending ? -1 : 1);
};

// -----------------------------------------------------------------------------
// Helper functions
// -----------------------------------------------------------------------------

const padNumber = (num: number, length: number) => {
  return num.toString().padStart(length, '0');
};
