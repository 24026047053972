import { CSSProperties } from 'react';

export const ErrorView = ({
  title,
  description,
  style,
}: {
  title?: string;
  description?: string;
  style?: CSSProperties;
}) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2rem',
        ...style,
      }}
    >
      <h3
        style={{
          color: '#fb4d3d',
          fontWeight: 500,
          fontSize: '24px',
          margin: '0',
        }}
      >
        {title || 'Unexpected Error'}
      </h3>
      <p
        style={{
          color: '#808080',
          fontSize: '16px',
          maxWidth: '480px',
          textAlign: 'center',
        }}
      >
        {description ||
          'If this page does not reload by itself in the next few seconds, please try refreshing the page manually.'}
      </p>
    </div>
  );
};
