import { MantineThemeOverride } from '@mantine/core';

export const theme: MantineThemeOverride = {
  // fontFamily: 'Inter, sans-serif',

  colors: {
    // Primary link color shades (lightest to darkest)
    primary: [
      '#ffe6f9', // Lightest
      '#ffb3e6',
      '#ff80d3',
      '#ff4dc0',
      '#ff1aac',
      '#ff00bf', // Original color
      '#e600ac',
      '#cc0099',
      '#b30085',
      '#990073', // Darkest
    ] as const, // This enforces the exact length and structure

    // Hover link color shades (lightest to darkest)
    secondary: [
      '#e6ffe6', // Lightest
      '#b3ffb3',
      '#80ff80',
      '#4dff4d',
      '#1aff1a',
      '#05ff00', // Original hover color
      '#04e600',
      '#03cc00',
      '#02b300',
      '#019900', // Darkest
    ] as const,

    // Body text color shades (lightest to darkest)
    dark: [
      '#f2f2f2', // Lightest
      '#e0e0e0',
      '#cccccc',
      '#b3b3b3',
      '#999999',
      '#808080', // Mid-tone gray
      '#666666',
      '#4d4d4d',
      '#333333', // Original color for body text
      '#1a1a1a', // Darkest
    ] as const,

    // Input border color shades (lightest to darkest)
    gray: [
      '#f9f9f9', // Lightest
      '#f2f2f2',
      '#eaeaea',
      '#e0e0e0',
      '#d9d9d9',
      '#cccccc', // Original input border color
      '#b3b3b3',
      '#999999',
      '#808080',
      '#666666', // Darkest
    ] as const,

    pink: [
      '#ff66d9',
      '#ff4dd2',
      '#ff33cc',
      '#ff1ac5',
      '#ff00bf',
      '#e600ac',
      '#cc0099',
      '#b30085',
      '#990070',
      '#80005d',
    ],
    lime: [
      '#69ff66',
      '#50ff4d',
      '#37ff33',
      '#1eff1a',
      '#05ff00',
      '#05e600',
      '#04cc00',
      '#04b300',
      '#039900',
      '#038000',
    ],
    blue: [
      '#66b0ff',
      '#4da3ff',
      '#3395ff',
      '#1a88ff',
      '#007bff',
      '#006fe6',
      '#0062cc',
      '#0056b3',
      '#004a99',
      '#003e80',
    ],
    red: [
      '#fd948b',
      '#fc8277',
      '#fc7164',
      '#fb5f50',
      '#fb4d3d',
      '#e24537',
      '#c93e31',
      '#b0362a',
      '#972e23',
      '#7e261c',
    ],
  },
  radius: {
    xs: '2px',
    sm: '4px',
    md: '8px',
    lg: '16px',
    xl: '32px',
  },
  shadows: {
    md: '1px 1px 3px rgba(0, 0, 0, .25)',
    xl: '5px 5px 3px rgba(0, 0, 0, .25)',
  },
  headings: {
    sizes: {
      h1: { fontSize: '1.75rem' },
      h2: { fontSize: '1.5rem' },
      h3: { fontSize: '1rem' },
      h4: { fontSize: '0.875rem' },
    },
  },
  components: {
    ActionIcon: {
      defaultProps: {
        color: 'gray',
        variant: 'subtle',
      },
      styles: {
        root: {
          // Override default 'button' styles.
          boxShadow: 'none',
          border: 'none',
          margin: 0,
        },
      },
    },
  },
};
