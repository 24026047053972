import { createContext, useContext } from 'react';
import { AuthenticatedSession } from './authenticatedSession';

export const AuthenticatedSessionContext = createContext<
  AuthenticatedSession | undefined
>(undefined);

export const useAuthenticatedSession = () => {
  return useContext(AuthenticatedSessionContext);
};
